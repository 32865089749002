.app {
  text-align: center;
  height: 100vh;
  display: flex;

  .page {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
  }
}

.app-link {
  color: #61dafb;
}

.bubble {
  position: relative;

  &:before {
    position: absolute;
    content: '\A';
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent rgb(209 213 219 / var(--tw-bg-opacity)) transparent transparent;
    top: -15px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
}
